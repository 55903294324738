import React,{useRef,useEffect} from 'react'
import './ContactBanner.css'
import gsap from 'gsap';

const ContactBanner = () => {

  const buttonRef = useRef(null);
          useEffect(() => {
            gsap.to("#bizButton", {
              y:10,
              scale: 1.02,
              duration: 1,
              scrollTrigger: {
                trigger: "#bizButton",
                start: "-360px center",
                end: "top 600px",
                scrub: 2,
              }
            });
          }, []);
  return (
    <div className='contactBanner'>
        <div className='container'>
          <div className='contactButton' id='bizButton'>
            <h2 className='baslik2' style={{color:'#fff'}}><span className='span' >Hala Rezervasyon yapmadınız mı ?</span>
                Kiralık karavan için bize ulaşın.
            </h2>
            <a href='tel:05534740847' className='bannerButton'> <i class="fa-solid fa-arrow-right"></i> İletişime Geç </a>
          </div>
            
        </div>
    </div>
  )
}

export default ContactBanner