import React,{useLayoutEffect,useState,useEffect} from 'react'
import './Home.css'
import { TypeAnimation } from 'react-type-animation'
import Services from '../Services/Services'
import HowTo from '../HowTo/HowTo'
import { Helmet,HelmetProvider } from 'react-helmet-async'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import CounterUp from '../CounterUp/CounterUp'
import BizKimiz from '../BizKimiz/BizKimiz'
import ContactBanner from '../ContactBanner/ContactBanner'
import Modal from '../Modal/Modal'

const Home = () => {

  useLayoutEffect(() => {
    let mm = gsap.matchMedia();
    gsap.registerPlugin(ScrollTrigger);
    mm.add('(min-width: 800px)', () => {
      let skews = document.querySelectorAll('.home');
      skews.forEach((skew) => {
        let clip_polygon = gsap.timeline({
          scrollTrigger: {
            trigger: skew,
            start: 'top top',
            scrub: 1,
          },
        });
        clip_polygon.fromTo(
          skew,
          {
            clipPath:
              'polygon(50% 0%, 100% 0, 100% 43%, 100% 100%, 50% 100%, 48% 100%, 0 100%, 0% 43%, 0 0)',
          },
          {
            clipPath:
              'polygon(50% 0%, 100% 0, 100% 43%, 100% 79%, 50% 100%, 50% 100%, 0 81%, 0% 43%, 0 0)',
          }
        );
      });
    });
    return () => mm.revert();
  });

  const [openModal, setOpenModal] = useState(false);
  return (
    <HelmetProvider>
    <>
     <Helmet>
            <title>Anasayfa | Vizyonsuz Karavan Kiralama </title>
            <meta property="og:description" content="Vizyonsuz Karavan Kiralama,Türkiye'nin birçok farklı bölgesine karavan kiralama hizmeti vermektedir. Her bütçeye uyum sağlayan kiralık karavanları, uzman kadrosu ve geniş bir ..."></meta>
        </Helmet>
    <div className="home">
      <div className="homeText"><h1 className='headerbaslik'>Özgürlüğün Tadını Karavanla Çıkarın !</h1></div>
      <div className="homeText">
        <p className='baslikText'>
              <TypeAnimation
                sequence={[
                  "Keşiflerinizi Yazmaya Hazır Mısınız?",
                  2000,
                  "Doğanın Sunduğu Eşsiz Anıları Keşfedin",
                  2000,
                  "Karavanla Özgürlüğün Tadını Çıkarın",
                  2000,
                  "Neredeyseniz Orası Eviniz Olsun",
                  2000,
                  "Karavanla Özgürce Seyahat Edin",
                  2000,
                ]}
                speed={50}
                repeat={Infinity}
                cursor={true}
            /></p></div>
            <div className='homeText'>
              <button class="homeBtn type1">
                <span class="btn-txt"><a href="tel:05534740847">İletişime Geç</a></span>
              </button>
            </div>
              <section id="section07" class="demo">
               
                <a href="#hizmetler"><span></span><span></span><span></span></a>
              </section>
              
      </div>
      {/* <div className='modall'>
      <button 
      onClick={() => setOpenModal(true)} 
      className='modalButton'>
        Ücretsiz Bilet Linki
      </button>
      <Modal 
      open={openModal} 
      onClose={() => setOpenModal(false)} />
      </div> */}
    <Services/>
    <HowTo/>
    <CounterUp/>
    <BizKimiz/>
    <ContactBanner/>
    </>
    </HelmetProvider>
  )
}

export default Home