import React,{useLayoutEffect,useEffect} from 'react'
import './Contact.css'
import ContactBanner from '../ContactBanner/ContactBanner'
import BreadCrump from '../BreadCrump/BreadCrump'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import PhoneGif from '../Assets/telephone.gif'
import ClockGif from '../Assets/clock.png'

const Contact = () => {
      // contact

    useLayoutEffect(() => {
        let mm = gsap.matchMedia();
        gsap.registerPlugin(ScrollTrigger);
        mm.add('(min-width: 320px)', () => {
          let skews = document.querySelectorAll('.clock');
          skews.forEach((skew) => {
            let clip_polygon = gsap.timeline({
              scrollTrigger: {
                trigger: skew,
                start: 'top bottom',
                end:'top bottom',
                scrub: true,
              },
            });
            clip_polygon.fromTo(
              skew,
              {
                clipPath:
                  'polygon(50% 0, 50% 100%, 50% 100%, 50% 0)',
              },
              {
                clipPath:
                  'polygon(100% 0, 100% 100%, 0 100%, 0 0)',
              }
            );
          });
        });
        return () => mm.revert();
      });


      
  return (
    <>
    <BreadCrump backtotop='#iletisim' title='İletişim'/>
        <div className="container" id='iletisim'>
                <div className="iletisimForm">
                  <div className='phoneGif'> <img  src={PhoneGif} alt="" width='350px' height='350px'/></div>
                    <div className="iletisimBlg">
                        <h3 className='baslik3'>Bizimle İletişime Geçin !</h3>
                        <a className='contactText' href="mailto:info@vizyonsuzkaravan.com"><p ><span style={{color:'#454545',fontWeight:'700'}}>E-Posta :</span> info@vizyonsuzkaravan.com</p></a>
                        <a href="tel:05534740847"><p className='contactText'><span style={{color:'#454545',fontWeight:'700'}}>Telefon :</span> +90 553 474 0847</p></a>
                        <a href="https://www.google.com/maps?gs_lcrp=EgZjaHJvbWUqEggBEC4YJxivARjHARiABBiKBTIGCAAQRRg8MhIIARAuGCcYrwEYxwEYgAQYigUyBggCEEUYOTIGCAMQRRg7MgoIBBAAGLEDGIAEMgYIBRBFGDwyBggGEEUYPTIGCAcQRRg90gEIMzY2NWowajSoAgCwAgA&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KdtBVD7XlbsUMf7U1L8LrTrv&daddr=Emek,+BODRUM+BULVARI+NO:14/A,+48200+Milas/Mu%C4%9Fla"><p className='contactText'><span style={{color:'#454545',fontWeight:'700'}}>Adres :</span> Emek, BODRUM BULVARI NO:14/A, 48200 Milas/Muğla</p></a>
                    </div>
                  </div>
                  <div className="ClockBlg">
                    <div className='clock'>
                      <h3 className='baslik3'>Çalışma Saatlerimiz</h3>
                      <p>Pazartesi <b style={{color:'rgb(239,158,31)'}}>-----</b> <strong >08:00 - 22:00</strong></p>
                      <hr />
                      <p>Salı <b style={{color:'rgb(239,158,31)'}}>-----</b>  <strong>08:00 - 22:00</strong></p>
                      <hr />
                      <p>Çarşamba <b style={{color:'rgb(239,158,31)'}}>-----</b> <strong>08:00 - 22:00</strong></p>
                      <hr />
                      <p>Perşembe <b style={{color:'rgb(239,158,31)'}}>-----</b> <strong>08:00 - 22:00</strong></p>
                      <hr />
                      <p>Cuma <b style={{color:'rgb(239,158,31)'}}>-----</b> <strong>08:00 - 22:00</strong></p>
                      <hr />
                      <p>Cumartesi <b style={{color:'rgb(239,158,31)'}}>-----</b> <strong>08:00 - 22:00</strong></p>
                      <hr />
                      <p>Pazar <b style={{color:'rgb(239,158,31)'}}>-----</b> <strong>08:00 - 22:00</strong></p>
                      
                    </div>
                        <div className='phoneGif'> <img className='clockpng'  src={ClockGif} alt="" width='300px' height='300px'/></div>
                    </div>
                <div>
                

               </div>
               <div className="map">
                
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3174.271109829775!2d27.776666575860975!3d37.288707972111894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bb95d73e5441db%3A0xef3aad0bbfd4d4fe!2sVizyonsuz%20Karavan%20Kiralama!5e0!3m2!1str!2str!4v1700597881855!5m2!1str!2str" width="100%" height="100%" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                </iframe>
               </div>
        </div>
        <ContactBanner/>
    </>
  )
}

export default Contact