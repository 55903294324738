import React from 'react'
import BreadCrump from '../BreadCrump/BreadCrump'
import Videos from './Videos'

const VideoGallery = () => {
  return (
    <div>
        <BreadCrump title='Video Galeri' backtotop='galeri'/>
        <Videos/>
    </div>
  )
}

export default VideoGallery