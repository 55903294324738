import React,{useState,useRef,useEffect} from 'react'
import './Navbar.css'
import {Outlet } from 'react-router-dom'




const Navbar = () => {
    const [navBarActive, setNavBarActive] = useState(false);
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };



    const handleHamburgerClick = () => {
    setNavBarActive(!navBarActive);
    setIsHamburgerOpen(!isHamburgerOpen);
    };
    const navRef = useRef();
    const hamburgerRef = useRef();
    
    const handleClickOutside = (event) => {
        if (
            (navRef.current && !navRef.current.contains(event.target)) &&
            (hamburgerRef.current && !hamburgerRef.current.contains(event.target))
        ) {
            setNavBarActive(false);
            setIsHamburgerOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const body = document.body;
    let lastScroll = 0;

    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 200) {
        body.classList.remove("scroll-up");
        return;
        }

        if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
        body.classList.remove("scroll-up");
        body.classList.add("scroll-down");
        } else if (
        currentScroll < lastScroll &&
        body.classList.contains("scroll-down")
        ) {
        body.classList.remove("scroll-down");
        body.classList.add("scroll-up");
        }
        lastScroll = currentScroll;
    });
    
  return (
    <div>
        <header className='desktop-head'>
            
           
            <nav  className={`nav-bar ${navBarActive ? 'active' : ''}`} >
                <ul>
                    <li><a className='active' href="/">Anasayfa</a></li>
                    <li><a className='active' href="/hizmetlerimiz">Hizmetlerimiz</a></li>
                    <div className="logo"><a href="/"><img src="/img/vizyonsuz-logo.png" alt="Vizyonsuz Karavan Logo" width='250' height='200'/></a></div>
                    <li><a className='active' href="">Hakkımızda <i  class="fa-solid fa-caret-down"></i></a>
                        <ul>
                            <li><a href="/foto-galeri">Foto Galeri</a></li>
                            <li><a href="/video-galeri">Video Galeri</a></li>
                            <li><a href="/hakkimizda">Hakkımızda</a></li>
                        </ul></li>
                    <li><a className='active' href="/iletisim">İletişim</a></li>
                </ul>
            </nav>
                <a href="/iletisim" className='header-btn' style={{color:'#fefefe'}}>
                    <button className="button">
                        Rezervasyon
                        <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
                            <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
                        </svg>
                    </button>
                </a>
              
        </header> 
        {/* mobile */}
        <header className='mobile-head'>
            
                <div className="logo"><a href="/"><img src="/img/vizyonsuz-logo.png" alt="Vizyonsuz Karavan Logo" width='250' height='200'/></a></div>

                <div ref={hamburgerRef} className={`hamburger ${isHamburgerOpen ? 'open' : ''}`} onClick={handleHamburgerClick}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            <nav ref={navRef} className={`nav-bar ${navBarActive ? 'active' : ''}`}>
                <ul >
                    <li><a className='active' href="/">Anasayfa</a></li>
                    <li><a className='active' href="/hizmetlerimiz">Hizmetlerimiz</a></li>
                    <li><a className='active' href="/hakkimizda">Hakkımızda </a>
                    
                        <ul >
                            <li><a href="/foto-galeri">Foto Galeri</a></li>
                            <li><a href="/video-galeri">Video Galeri</a></li>
                           
                        </ul>
                    </li>
                    
                    <li><a className='active' href="/iletisim">İletişim</a></li>
                    <a href="tel:05534740847" className='btn' style={{color:'#fefefe',display:'flex',textAlign:'center',padding:'10px 5px',justifyContent:'center',borderRadius:'7px',background:'rgb(0 107 179)'}}>
                        <button className="" style={{color:'#fefefe',display:'flex',textAlign:'center',alignItems:'center'}}>
                            <div>
                            <i style={{paddingRight:'10px'}} class="fa-solid fa-phone"></i>
                                <p style={{display:'flex',fontWeight:'600'}}>+90 553 474 0847</p>
                            </div>
                        </button>
                    </a>
                </ul>
            </nav>
              
                <a href="tel:05534740847" className='header-btn' style={{color:'#fefefe'}}>
                    <button className="button">
                        Rezervasyon
                        <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
                            <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
                        </svg>
                    </button>
                </a>
        </header> 
                
               

        <main>
            <Outlet/>
        </main>
    </div>
  )
}

export default Navbar
