import React from 'react'
import './Footer.css'

import FooterBanner from '../Assets/footer-banner.svg'
import Caravan from '../Caravan/Caravan'

const Footer = () => {
  return (
    <footer>
      <Caravan/>
       <div className='foot'>
          <div className='container'>
            <div className='sec aboutus'>
              <a href="" className='footer-logo' style={{display:'flex',justifyContent:'center'}}><img src='img/vizyonsuz-logo.png' className='' alt="logo" width='50%' height='200px' role='logo' /></a>

              <div className="middle">
                  <a className='social-btn' href="https://www.facebook.com/vizyonsuzkaravan">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a className='social-btn' href="https://www.instagram.com/invites/contact/?i=17u5kbpw695l2&utm_content=m26dnui">
                  <i class="fa-brands fa-instagram"></i>
                  </a>
                  <a className='social-btn' href="https://www.tiktok.com/@vizyonsuzkaravan?_d=secCgYIASAHKAESPgo8dnc1L4JWqkaFOb5Sc3tl%2FMuJXu0u82JFZ5aB6t5%2BPfQMZOJPohNbaVcdPOGX2BDDUQHluvjoMU0pLJnfGgA%3D&_r=1&checksum=bde9fb70074870644e4daf813716b8ab4ab2fdd4e771a19ff846fbd741820268&language=tr&sec_uid=MS4wLjABAAAA7ZJUN_bOi6CN8iUFNWHvtQSwZ3mf3ZRHSJ8wntiRVsHYCbC3CmcA6uvjvO8BT4SE&sec_user_id=MS4wLjABAAAA7ZJUN_bOi6CN8iUFNWHvtQSwZ3mf3ZRHSJ8wntiRVsHYCbC3CmcA6uvjvO8BT4SE&share_app_id=1233&share_author_id=7007294839592059909&share_link_id=130A7704-CB60-478F-8826-AA6A59D6ACFE&source=h5_m&tt_from=more&u_code=dkh805m91cabh4&user_id=7007294839592059909&utm_campaign=client_share&utm_medium=ios&utm_source=more">
                  <i class="fa-brands fa-tiktok"></i>
                  </a>
                  <a className='social-btn' href="https://youtube.com/@vizyonsuzkaravan7323?si=mm1tm_WPDV7vzAra">
                  <i class="fa-brands fa-youtube"></i>
                  </a>
                  <a className='social-btn' href="https://x.com/vizyonszkaravan?s=21&t=XvAigBk3OQfAGw2sVMxkvQ">
                  <i class="fa-brands fa-x-twitter"></i>
                  </a>
                  

              </div>
              <a href='https://www.google.com/search?gs_ssp=eJzj4tVP1zc0LDQxMysyzEozYLRSNagwNElKsjRNMTdONTUxMUxJsjKoSE0zTkxMMUhKSksxSTFJS_USLMusqszPKy6tUshOLEosS8wDAAmOFyQ&q=vizyonsuz+karavan&oq=viz&gs_lcrp=EgZjaHJvbWUqEggBEC4YJxivARjHARiABBiKBTIGCAAQRRg8MhIIARAuGCcYrwEYxwEYgAQYigUyDAgCECMYJxiABBiKBTIGCAMQRRg5MgYIBBBFGDsyDAgFEAAYQxiABBiKBTIGCAYQRRg8MgYIBxBFGD3SAQgxNzE1ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x14bb95d73e5441db:0xef3aad0bbfd4d4fe,1,,,,' style={{background:'rgb(0 107 179)',padding:'10px',borderRadius:'5px'}}>
                <p style={{color:'#fff',textAlign:'center',fontWeight:'700'}}>Müşterilerimiz Bizim İçin Nasıl Düşünüyor?</p>
              </a>
              
            </div>
            <div className="sec quicklinks">
              <h2>Sayfalar</h2>
              <ul>
                <li><a href="/">Anasayfa</a></li>
                <li><a href="/hizmetlerimiz">Hizmetlerimiz</a></li>
                <li><a href="/hakkimizda">Hakkımızda</a></li>
                <li><a href="/foto-galeri">Galeri</a></li>
                <li><a href="/iletisim">İletişim</a></li>
              </ul>
            </div>
            <div className="sec quicklinks">
              <h2>Hizmetler</h2>
              <ul>
                <li><a href="/hizmetlerimiz">Sabit Karavan Kiralama</a></li>
                <li><a href="/hizmetlerimiz">MotoKaravan Kiralama</a></li>
               
                
              </ul>
            </div>
            <div className="sec contact">
              <h2>İletişim</h2>
              <ul className="info">
                <li>
                  <span><ion-icon name="call-outline"></ion-icon><p><a href="tel:05534740847" data-replace="this link">+90 553 474 0847</a></p></span>
                </li>
                <li>
                  <span><ion-icon name="mail-outline"></ion-icon><p><a href="mailto:info@vizyonsuzkaravan.com">info@vizyonsuzkaravan.com</a></p></span>
                </li>
                <li>
                  <span><ion-icon style={{fontSize:'37px'}} name="pin"></ion-icon><p><a href="https://www.google.com/maps/dir/41.1529147,27.795971/vizyonsuz+karavan/@39.2288246,25.727677,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14bb95d73e5441db:0xef3aad0bbfd4d4fe!2m2!1d27.7792415!2d37.288708?entry=ttu">Emek, BODRUM BULVARI NO:14/A,Milas/Muğla</a></p></span>
                </li>
              </ul>
            </div>
          <img src={FooterBanner} className='footerban' alt="" />
          </div>
        </div>
          <div className='copyrightText'>
            <div className='container'>
              <p> © 2023 Vizyonsuz Karavan Kiralama | Tüm Hakları Saklıdır.</p>
            <div>
              <a style={{fontWeight:'700',fontSize:'18px',color:'black'}} href="http://www.plazayazilim.com">Plaza Yazılım</a>
            </div>
            </div>
          </div>
         
    </footer>
  )
}

export default Footer