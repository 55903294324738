import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Pages
import Home from "./components/Home/Home";
import Footer from "./components/Layout/Footer";
import Navbar from "./components/Layout/Navbar";
import WpButton from "./components/WpButton/WpButton";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Hizmetler from "./components/Hizmetler/Hizmetler";
import Images from "./components/Images/Images";
import VideoGallery from "./components/Images/VideoGallery";

const router = createBrowserRouter([
  {
    path:'/',
    element:<Navbar />,
    children:[
    {index:true,element: (<Home />),},
    {path: "/anasayfa",element: (<Home />),},
    {path: "/hakkimizda",element: (<About />),},
    {path: "/iletisim",element: (<Contact />),},
    {path: "/hizmetlerimiz",element: (<Hizmetler />),},
    {path: "/foto-galeri",element: (<Images />),},
    {path: "/video-galeri",element: (<VideoGallery />),},
    
  ]
  }
])

function App() {
  return (
    <div>
      <div>
        <RouterProvider router={router} />
        <WpButton/>
        <Footer/>
    </div>
    </div>
  );
}

export default App;
