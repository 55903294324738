import React,{useLayoutEffect,useEffect} from 'react'
import './About.css'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { SplitWord} from './Animations';

import { Helmet ,HelmetProvider } from 'react-helmet-async';

import Karavan1 from '../Assets/1.jpg';
import KaravanIc from '../Assets/6.jpg';
import Karavan2 from '../Assets/7.jpg';
import KaravanIc2 from '../Assets/5.jpg';
import BreadCrump from '../BreadCrump/BreadCrump';

const About = () => {
    useLayoutEffect(() => {
        let mm = gsap.matchMedia();
        gsap.registerPlugin(ScrollTrigger);
        mm.add('(min-width: 300px)', () => {
          let skews = document.querySelectorAll('.About');
          skews.forEach((skew) => {
            let clip_polygon = gsap.timeline({
              scrollTrigger: {
                trigger: skew,
                start: 'top top',
                scrub: 1,
              },
            });
            clip_polygon.fromTo(
              skew,
              {
                clipPath:
                  'polygon(50% 0%, 100% 0, 100% 43%, 100% 100%, 50% 100%, 48% 100%, 0 100%, 0% 43%, 0 0)',
              },
              {
                clipPath:
                  'polygon(50% 0%, 100% 0, 100% 43%, 100% 79%, 50% 100%, 50% 100%, 0 81%, 0% 43%, 0 0)',
              }
            );
          });
        });
        return () => mm.revert();
      });



      useEffect(()=>{
        let mm = gsap.matchMedia();
      mm.add("(min-width:768px)",()=>{
        gsap.to("#top", {
          y: -40,
          duration: 1,
          scrollTrigger: {
            trigger: "#top",
            start: "top top",
            end: "bottom 200px",
            scrub: 2,
          }
        });
      })
      return () => mm.revert();
      })

      // soldaki imageler
      useEffect(()=>{
        let mm = gsap.matchMedia();
      mm.add("(min-width:1024px)",()=>{
        gsap.to("#imagee", {
         
          y: -1,
          duration: 2,
          rotation: 30,
          opacity:1,
          scrollTrigger: {
            trigger: "#imagee",
            start: "top bottom",
            end: "bottom bottom",
            scrub: 2,
          }
        });
      })
      return () => mm.revert();
      })

      useEffect(()=>{
        let mm = gsap.matchMedia();
      mm.add("(min-width:1024px)",()=>{
        gsap.to("#imagee2", {
         opacity:.7,
          y: -100,
          duration: 2,
          rotation: 0,
         
          scrollTrigger: {
            trigger: "#imagee2",
            start: "top bottom",
            end: "bottom bottom",
            scrub: 2,
          }
        });
      })
      return () => mm.revert();
      })
      useEffect(()=>{
        let mm = gsap.matchMedia();
      mm.add("(min-width:1024px)",()=>{
        gsap.to("#imagee4", {
         opacity:.7,
          y: -100,
          duration: 2,
          rotation: 10,
         
          scrollTrigger: {
            trigger: "#imagee4",
            start: "top bottom",
            end: "bottom bottom",
            scrub: 2,
          }
        });
      })
      return () => mm.revert();
      })
      // sağdaki image
      useEffect(()=>{
        let mm = gsap.matchMedia();
      mm.add("(min-width:1024px)",()=>{
        gsap.to("#imagee3", {
         
          y: -1,
          duration: 2,
          rotation: -30,
          opacity:1,
          scrollTrigger: {
            trigger: "#imagee3",
            start: "top bottom",
            end: "bottom bottom",
            scrub: 2,
          }
        });
      })
      return () => mm.revert();
      })

      


  return (
    <HelmetProvider>
    <>
     <Helmet>
        <title>Hakkımızda | Vizyonsuz Karavan Kiralama®</title>
        <meta name="description" content="Vizyonsuz Karavan Kiralama bünyesinde 4 adet 3.90 çekme karavan bulunmaktadır. Tüm karavanlarımızın içerisinde Tüplü şofbeninden güneş paneline(220 V) tenteden webastoya (ısıtıcı) kadar donanımlar mevcut olup,"></meta>
        <meta property="og:description" content="Vizyonsuz Karavan Kiralama bünyesinde 4 adet 3.90 çekme karavan bulunmaktadır. Tüm karavanlarımızın içerisinde Tüplü şofbeninden güneş paneline(220 V) tenteden webastoya (ısıtıcı) kadar donanımlar mevcut olup,"></meta>
        
      </Helmet>
      <BreadCrump title='Hakkımızda' backtotop='#hizmetlerr'/>
      <div className='' id='hizmetlerr'>
          <div className="image-wrapper" >
            <div id='imagee' className="image shadow-lg w-[66%]  rotate-[-25deg] translate-y-[40%] translate-x-[-33%] md:w-full md:rotate-0 md:translate-x-0 md:translate-y-0 md:aspect-unset md:h-[350px] sm:h-[300px] xs:h-[250px]"  style={{zIndex:'6'}}>
              <img class="full-cover" src={Karavan1} loading="lazy"/>
            </div>
            <div id='imagee4' className="image shadow-lg rounded-[5px] overflow-hidden isolate" >
              <img class="full-cover" src={KaravanIc} loading="lazy"/>
            </div>
          </div>
        

          <div className='aboutContent'>

              <h3 className='vizyonsuzBslk'>Vizyonsuz Karavan Kiralama</h3>
            
              <p className='icerikText'><SplitWord><span style={{textAlign:'center',fontSize:'21px',fontWeight:'700',color:'#454545',marginBottom:'2rem',marginTop:'4rem'}}>Biz  <strong style={{fontWeight:'800'}}>Kimiz</strong></span></SplitWord>
              <SplitWord>
                2020 yılında kendi karavanımızla başladığımız bu yolculukla tüm Ege’yi karavanımızla dolaşıp egenin küçük ve şirin ilçesi olan Milas’a yerleşerek noktaladık. Kullandığımız karavanı kiralama fikri pandemi ile başladı ve çok güzel dostluklar edindik.</SplitWord>
                <SplitWord><span style={{textAlign:'center',fontSize:'21px',fontWeight:'700',color:'#454545',marginBottom:'2rem',marginTop:'5rem'}}>İsmimiz Neden <strong style={{fontWeight:'800'}}>VİZYONSUZ</strong> </span> </SplitWord>
                <SplitWord>İsmimizi bu şekilde seçmemizin bir nedeni var.
                  Absürt olan akılda kalır ve doğrusundan daha da çok kalıcı olur psikolojisi ile alakalı konulmuştur. Tüm bizi arayan müşterilerimiz ismimizi unutamadığını aklına hemen geldiğini söylerler.  Sonuç olarak <strong style={{fontWeight:'800',color:'#454545'}}>VİZYONSUZ</strong> VİZYONLUYUZ :).

                  Ayrıca Türk Patent Enstitüsü marka tescilimiz bulunmaktadır.<strong style={{fontWeight:'800',color:'#454545'}}> Vizyonsuz Karavan Kiralama</strong> bir MARKADIR.</SplitWord>
                  <SplitWord><span style={{textAlign:'center',fontSize:'21px',fontWeight:'700',color:'#454545',marginBottom:'2rem',marginTop:'5rem'}}>Karavanlarımızı <strong style={{fontWeight:'800'}}>Tanıyalım </strong></span></SplitWord>
                
                  <SplitWord>Şu an <strong style={{fontWeight:'800',color:'#454545'}}>Vizyonsuz Karavan Kiralama</strong> bünyesinde 4 adet 3.90 çekme karavan bulunmaktadır. Tüm karavanlarımızın içerisinde Tüplü şofbeninden güneş paneline(220 V) tenteden webastoya (ısıtıcı) kadar donanımlar mevcut olup, 4 kişinin ihtiyacını karşılayacak mutfak, yatak ve kamp malzemeleri de bulunmaktadır.</SplitWord> 
                  <SplitWord><span style={{textAlign:'center',fontSize:'21px',fontWeight:'700',color:'#454545',marginBottom:'2rem',marginTop:'5rem'}}>Gelecek  <strong style={{fontWeight:'800'}}>Planlarımız</strong></span></SplitWord>
                
                  <SplitWord> Kiralama ile başladığımız bu yolculukta, motokaravan ve çekme karavan üretimi satışı, sahne karavan kiralama, dizi set karavan kiralama ve karavan otel kurmayı hedeflemekteyiz</SplitWord></p>
                
          </div>

          <div class="image-wrapper iki">
            <div id='imagee3' class="image shadow-lg w-[66%] rounded-[5px] overflow-hidden isolate aspect-[8/7] rotate-[-25deg] z-5 translate-y-[40%] relative translate-x-[-33%] md:w-full md:rotate-0 md:translate-x-0 md:translate-y-0 md:aspect-unset md:h-[350px] sm:h-[300px] xs:h-[250px]" style={{zIndex:'6'}}>
              <img class="full-cover" src={KaravanIc2} loading="lazy"/>
            </div>
            <div id='imagee2' class="image shadow-lg rounded-[5px] ">
              <img class="full-cover" src={Karavan2} loading="lazy"/>
            </div>
          </div>
      </div>
    </>
    </HelmetProvider>
  )
}

export default About