import React, { useState } from 'react'
import './Gallery.css'

import Img1 from '../Assets/VizyonsuzGlr/100.jpg'
import Img2 from '../Assets/6.jpg'
import Img3 from '../Assets/VizyonsuzGlr/102.jpg'
import Img4 from '../Assets/VizyonsuzGlr/103.jpg'
import Img5 from '../Assets/VizyonsuzGlr/104.jpg'
import Img6 from '../Assets/VizyonsuzGlr/105.jpg'
import Img7 from '../Assets/VizyonsuzGlr/106.jpg'
import Img8 from '../Assets/VizyonsuzGlr/107.jpg'
import Img9 from '../Assets/VizyonsuzGlr/108.jpg'
import Img10 from '../Assets/VizyonsuzGlr/109.jpg'
import Img11 from '../Assets/VizyonsuzGlr/110.jpg'
import Img12 from '../Assets/VizyonsuzGlr/111.jpg'
import Img13 from '../Assets/VizyonsuzGlr/112.jpg'
import Img14 from '../Assets/VizyonsuzGlr/113.jpg'
import Img15 from '../Assets/VizyonsuzGlr/114.jpg'
import Img16 from '../Assets/VizyonsuzGlr/115.jpg'
import Img17 from '../Assets/VizyonsuzGlr/116.jpg'
import Img18 from '../Assets/VizyonsuzGlr/117.jpg'
import Img19 from '../Assets/VizyonsuzGlr/118.jpg'
import Img20 from '../Assets/VizyonsuzGlr/119.jpg'
import Img21 from '../Assets/VizyonsuzGlr/120.jpg'
import Img22 from '../Assets/VizyonsuzGlr/121.jpg'
import Img23 from '../Assets/VizyonsuzGlr/123.jpg'
import Img24 from '../Assets/VizyonsuzGlr/124.jpg'
import Img25 from '../Assets/VizyonsuzGlr/125.jpg'
import Img26 from '../Assets/VizyonsuzGlr/126.jpg'
import Img27 from '../Assets/VizyonsuzGlr/127.jpg'
import Img28 from '../Assets/VizyonsuzGlr/128.jpg'
import Img29 from '../Assets/VizyonsuzGlr/129.jpg'
import Img30 from '../Assets/VizyonsuzGlr/130.jpg'
import Img31 from '../Assets/VizyonsuzGlr/131.jpg'
import Img32 from '../Assets/VizyonsuzGlr/132.jpg'
import Img33 from '../Assets/VizyonsuzGlr/133.jpg'
import Img34 from '../Assets/VizyonsuzGlr/134.jpg'
import Img35 from '../Assets/VizyonsuzGlr/135.jpg'
import Img36 from '../Assets/VizyonsuzGlr/136.jpg'
import Img37 from '../Assets/VizyonsuzGlr/137.jpg'
import Img38 from '../Assets/VizyonsuzGlr/138.jpg'
import Img39 from '../Assets/VizyonsuzGlr/139.jpg'
import Img40 from '../Assets/VizyonsuzGlr/122.jpg'
import Img41 from '../Assets/1.jpg'
import Img42 from '../Assets/5.jpg'

const Gallery = () => {
    let data =[
        {
            id:1,
            imgSrc:Img1,
        },
        {
            id:2,
            imgSrc:Img2,
        },
        {
            id:3,
            imgSrc:Img3,
        },
        {
            id:4,
            imgSrc:Img4,
        },
        {
            id:5,
            imgSrc:Img5,
        },
        {
            id:6,
            imgSrc:Img6,
        },
        {
            id:7,
            imgSrc:Img7,
        },
        {
            id:8,
            imgSrc:Img8,
        },
        {
            id:9,
            imgSrc:Img9,
        },
        {
            id:10,
            imgSrc:Img10,
        },
        {
            id:11,
            imgSrc:Img11,
        },
        {
            id:12,
            imgSrc:Img12,
        },
        {
            id:13,
            imgSrc:Img13,
        },
        {
            id:14,
            imgSrc:Img14,
        },
        {
            id:15,
            imgSrc:Img15,
        },
        {
            id:16,
            imgSrc:Img16,
        },
        {
            id:17,
            imgSrc:Img17,
        },
        {
            id:18,
            imgSrc:Img18,
        },
        {
            id:19,
            imgSrc:Img19,
        },
        {
            id:20,
            imgSrc:Img20,
        },
        {
            id:21,
            imgSrc:Img21,
        },
        {
            id:22,
            imgSrc:Img22,
        },
        {
            id:23,
            imgSrc:Img23,
        },
        {
            id:24,
            imgSrc:Img24,
        },
        {
            id:25,
            imgSrc:Img25,
        },
        {
            id:26,
            imgSrc:Img26,
        },
        {
            id:27,
            imgSrc:Img27,
        },
        {
            id:28,
            imgSrc:Img28,
        },
        {
            id:29,
            imgSrc:Img29,
        },
        {
            id:30,
            imgSrc:Img10,
        },
        {
            id:31,
            imgSrc:Img31,
        },
        {
            id:32,
            imgSrc:Img32,
        },
        {
            id:33,
            imgSrc:Img33,
        },
        {
            id:34,
            imgSrc:Img34,
        },
        {
            id:35,
            imgSrc:Img35,
        },
        {
            id:36,
            imgSrc:Img36,
        },
        {
            id:37,
            imgSrc:Img37,
        },
        {
            id:38,
            imgSrc:Img38,
        },
        {
            id:39,
            imgSrc:Img39,
        },
        {
            id:40,
            imgSrc:Img40,
        },
        {
            id:41,
            imgSrc:Img41,
        },
        {
            id:42,
            imgSrc:Img42,
        },
        
    ]
    const [model, setModel] =useState(false)
    const [tempimgSrc, setTempImgSrc] =useState(false)
    const getImg =(imgSrc)=>{
        setTempImgSrc(imgSrc);
        setModel(true);
    }
  return (
    <>
    <div className={model? "model open" : "model"}>
        <img src={tempimgSrc} loading='lazy' />
        <i class="fa-solid fa-xmark" onClick={()=>setModel(false)}></i>

    </div>
  
    <div className="gallery">
        {
            data.map((item,index)=>{
            return(
                <div className="pics" key={index} onClick={()=>getImg(item.imgSrc)} >
                    <img src={item.imgSrc} loading='lazy' />
                </div>
            )
            }) 
        }
    </div>

    </>
  )
}

export default Gallery