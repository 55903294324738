import React,{useLayoutEffect,useEffect} from 'react'
import './BreadCrump.css'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

const BreadCrump = (props) => {
    useLayoutEffect(() => {
        let mm = gsap.matchMedia();
        gsap.registerPlugin(ScrollTrigger);
        mm.add('(min-width: 300px)', () => {
          let skews = document.querySelectorAll('.breadcrump');
          skews.forEach((skew) => {
            let clip_polygon = gsap.timeline({
              scrollTrigger: {
                trigger: skew,
                start: 'top top',
                scrub: 1,
              },
            });
            clip_polygon.fromTo(
              skew,
              {
                clipPath:
                  'polygon(50% 0%, 100% 0, 100% 43%, 100% 100%, 50% 100%, 48% 100%, 0 100%, 0% 43%, 0 0)',
              },
              {
                clipPath:
                  'polygon(50% 0%, 100% 0, 100% 43%, 100% 79%, 50% 100%, 50% 100%, 0 81%, 0% 43%, 0 0)',
              }
            );
          });
        });
        return () => mm.revert();
      });



    useEffect(()=>{
        let mm = gsap.matchMedia();
      mm.add("(min-width:768px)",()=>{
        gsap.to("#top", {
          y: -40,
          duration: 1,
          scrollTrigger: {
            trigger: "#top",
            start: "top top",
            end: "bottom 200px",
            scrub: 2,
          }
        });
      })
      return () => mm.revert();
      })

  return (
    <div className='breadcrump' id='top'>
             <div className="homeText" ><h1 className='headerbaslik'>{props.title}</h1></div>
            
            <div class="vertical" id='top'><a href="/">Anasayfa</a><span class="destination">{props.title}</span></div>
            <section id="section07" class="demo">
                    
                    <a href={props.backtotop}><span></span><span></span><span></span></a>
            </section>
    </div>
  )
}

export default BreadCrump