import React,{useState} from 'react'
import './CounterUp.css'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'


const CounterUp = () => {
    const [counterOn  , setCounterOn] = useState(false);
   
  
  return (
    <div className='CounterUp'>
        <div className='container'>
            <div className="content">
                    <div className="box">
                        <div className="counterIcon"><i class="fa-solid fa-thumbs-up"></i></div>
                        <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)} >
                        <div className="counter baslik">{counterOn && <CountUp start={0} end={5} duration={2} delay={0} />} +</div>
                        </ScrollTrigger>
                        <div className="counterText cardBaslik">Yıllık Deneyim</div>
                    </div>
                    <div className="box">
                        <div className="counterIcon"><i class="fa-solid fa-caravan"></i></div>
                        <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
                        <div className="counter baslik">{counterOn && <CountUp start={0} end={5} duration={2} delay={0}/>} +</div>
                        </ScrollTrigger>
                        <div className="counterText cardBaslik">Çekme Karavan</div>
                    </div>
                    <div className="box">
                        <div className="counterIcon"><i class="fa-solid fa-handshake"></i></div>
                        <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
                        <div className="counter baslik">{counterOn && <CountUp start={0} end={659} duration={2} delay={0}/>} +</div>
                        </ScrollTrigger>
                        <div className="counterText cardBaslik">Memnun Müşteri</div>
                    </div>
                    <div className="box">
                        <div className="counterIcon"><i class="fa-solid fa-map-location"></i></div>
                        <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
                        <div className="counter baslik">{counterOn && <CountUp start={0} end={53} duration={2} delay={0}/>} +</div>
                        </ScrollTrigger>
                        <div className="counterText cardBaslik">Popüler Rota</div>
                    </div>
                
            </div>
            
            
       
        </div>
    </div>
  )
}

export default CounterUp