import React,{useEffect} from 'react'
import Karavanillust from '../Assets/karavan-illust.png'
import './Caravan.css'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';
const Caravan = () => {
    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
        gsap.to('.car', {
         
          scrollTrigger: {
            trigger: '.car',
            start:'20px 90% ',
            end:'+=450',
            // markers:true,
            toggleActions:'restart pause reverse pause',
            scrub: 2,
            
          },
          x:300,
          duration:3
        });
       
        
       
      }, []);



  return (
    <div className='Caravan'>
        <img className='car 'src={Karavanillust} alt="" width='450px' height='100px'/>
    </div>
  )
}

export default Caravan