import React,{useLayoutEffect,useEffect} from 'react'
import './Hizmetler.css'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import './css/embla.css'
import './css/sandbox.css'
import EmblaCarousel from './EmblaCarousel'
import { Helmet,HelmetProvider } from 'react-helmet-async'
import BreadCrump from '../BreadCrump/BreadCrump'

const OPTIONS = { dragFree: false ,loop:true }
const SLIDE_COUNT = 7
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())


const Hizmetler = () => {
    
  return (
    <HelmetProvider>
    <>
        <Helmet>
            <title>Hizmetlerimiz | Vizyonsuz Karavan Kiralama </title>
            <meta property="og:description" content="Vizyonsuz Karavan Kiralama,Türkiye'nin birçok farklı bölgesine karavan kiralama hizmeti vermektedir. Her bütçeye uyum sağlayan kiralık karavanları, uzman kadrosu ve geniş bir ..."></meta>
        </Helmet>
        <BreadCrump title='Hizmetlerimiz' backtotop='#hizmet'/>

        <a href="#iletisim"><span></span><span></span><span></span></a>
            <div className="container" >
                <div className='hizmetlerimiz' id='hizmet'>
                    <h2 className='baslik3'>Çekme Karavan Kiralama</h2>
                    <main className="sandbox">
    
                    <section className="sandbox__carousel">
                    <EmblaCarousel slides={SLIDES} options={OPTIONS} />
                    </section>
   
                     </main>
                     <div className='feature'>
                        <div className="grid grid_4">
            
                            <div className="nicdark_textevidence nicdark_border_grey nicdark_relative" style={{border:'1px solid rgb(241,241,241)',position:'relative',float:'left',overflow:'hidden',padding:'10px'}}>
                            
                            <img
                                alt=""
                                className="nicdark_width_fix_50 nicdark_absolute_left nicdark_absolute"
                                src="https://www.karavanci.com.tr/ups/img13.svg"
                                style={{
                               
                                left: '0px',
                                marginTop: '20px',
                                marginLeft: '20px',
                                width: '50px',
                                }}
                            />
                            <div className="nicdark_activity nicdark_marginleft100" style={{}}>
                                <h4 style={{ marginLeft: '0px', padding: '0px', fontWeight: 'normal' }}>
                                Yat Tipi Tuvalet & Duş
                                </h4>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left' }}>&nbsp;</div>
                                <div>
                                <p style={{ margin: '0px', padding: '0px', fontSize: '15px', lineHeight: '22px', color: 'rgb(164, 164, 164)' }}>
                                    Karavanımızda yat tipi tuvalet ve duş bulunmaktadır.
                                </p>
                                </div>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left'  }}>&nbsp;</div>
                            </div>
                            </div>
                            <div className="nicdark_textevidence nicdark_border_grey nicdark_relative" style={{border:'1px solid rgb(241,241,241)',position:'relative',float:'left',overflow:'hidden',padding:'10px'}}>
                            
                            <img
                                alt=""
                                className="nicdark_width_fix_50 nicdark_absolute_left nicdark_absolute"
                                src="https://www.karavanci.com.tr/ups/img15.png"
                                style={{
                               
                                left: '0px',
                                marginTop: '20px',
                                marginLeft: '20px',
                                width: '50px',
                                }}
                            />
                            <div className="nicdark_activity nicdark_marginleft100" style={{}}>
                                <h4 style={{ marginLeft: '0px', padding: '0px', fontWeight: 'normal' }}>
                                Klima
                                </h4>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left' }}>&nbsp;</div>
                                <div>
                                <p style={{ margin: '0px', padding: '0px', fontSize: '15px', lineHeight: '22px', color: 'rgb(164, 164, 164)' }}>
                                Karavanlarımızda seyahat ederken konforunuzu sağlayan analog klima bulunmaktadır. Ayarlanabilir özellikleriyle istediğiniz sıcaklığı sağlar, sessiz ve enerji verimlidir.
                                </p>
                                </div>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left'  }}>&nbsp;</div>
                            </div>
                            </div>
                            <div className="nicdark_textevidence nicdark_border_grey nicdark_relative" style={{border:'1px solid rgb(241,241,241)',position:'relative',float:'left',overflow:'hidden',padding:'10px'}}>
                            
                            <img
                                alt=""
                                className="nicdark_width_fix_50 nicdark_absolute_left nicdark_absolute"
                                src="https://www.karavanci.com.tr/ups/img16.png"
                                style={{
                               
                                left: '0px',
                                marginTop: '20px',
                                marginLeft: '20px',
                                width: '50px',
                                }}
                            />
                            <div className="nicdark_activity nicdark_marginleft100" style={{}}>
                                <h4 style={{ marginLeft: '0px', padding: '0px', fontWeight: 'normal' }}>
                                Kalorifer
                                </h4>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left' }}>&nbsp;</div>
                                <div>
                                <p style={{ margin: '0px', padding: '0px', fontSize: '15px', lineHeight: '22px', color: 'rgb(164, 164, 164)' }}>
                                    Karavanlarımızda Webasto tipi kalorifer bulunmaktadır.
                                </p>
                                </div>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left'  }}>&nbsp;</div>
                            </div>
                            </div>
                            <div className="nicdark_textevidence nicdark_border_grey nicdark_relative" style={{border:'1px solid rgb(241,241,241)',position:'relative',float:'left',overflow:'hidden',padding:'10px'}}>
                            
                            <img
                                alt=""
                                className="nicdark_width_fix_50 nicdark_absolute_left nicdark_absolute"
                                src="https://www.karavanci.com.tr/ups/img14.png"
                                style={{
                               
                                left: '0px',
                                marginTop: '20px',
                                marginLeft: '20px',
                                width: '50px',
                                }}
                            />
                            <div className="nicdark_activity nicdark_marginleft100" style={{}}>
                                <h4 style={{ marginLeft: '0px', padding: '0px', fontWeight: 'normal' }}>
                                    Şofben

                                </h4>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left' }}>&nbsp;</div>
                                <div>
                                <p style={{ margin: '0px', padding: '0px', fontSize: '15px', lineHeight: '22px', color: 'rgb(164, 164, 164)' }}>
                                    Sıcak su ihtiyacınızı karşılayabileceğiniz 40 derece ve 70 derece  kadar ısıtan webasto sistemi  bulunmaktadır
                                </p>
                                </div>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left'  }}>&nbsp;</div>
                            </div>
                            </div>
                            {/* ... diğer öğeler */}
                        </div>

                        {/* ikinci bölüm */}
                        <div className="grid grid_4">
            
                            <div className="nicdark_textevidence nicdark_border_grey nicdark_relative" style={{border:'1px solid rgb(241,241,241)',position:'relative',float:'left',overflow:'hidden',padding:'10px'}}>
                            
                            <img
                                alt=""
                                className="nicdark_width_fix_50 nicdark_absolute_left nicdark_absolute"
                                src="https://www.karavanci.com.tr/ups/img10.png"
                                style={{
                              
                                left: '0px',
                                marginTop: '20px',
                                marginLeft: '20px',
                                width: '50px',
                                }}
                            />
                            <div className="nicdark_activity nicdark_marginleft100" style={{}}>
                                <h4 style={{ marginLeft: '0px', padding: '0px', fontWeight: 'normal' }}>
                                Yaşam Aküsü
                                </h4>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left' }}>&nbsp;</div>
                                <div>
                                <p style={{ margin: '0px', padding: '0px', fontSize: '15px', lineHeight: '22px', color: 'rgb(164, 164, 164)' }}>
                                    Karavanlarımızda yaşam bölümüne enerji sağlayan jel aküler bulunmaktadır.
                                </p>
                                </div>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left'  }}>&nbsp;</div>
                            </div>
                            </div>
                            <div className="nicdark_textevidence nicdark_border_grey nicdark_relative" style={{border:'1px solid rgb(241,241,241)',position:'relative',float:'left',overflow:'hidden',padding:'10px'}}>
                            
                            <img
                                alt=""
                                className="nicdark_width_fix_50 nicdark_absolute_left nicdark_absolute"
                                src="https://www.karavanci.com.tr/ups/img11.png"
                                style={{
                               
                                left: '0px',
                                marginTop: '20px',
                                marginLeft: '20px',
                                width: '50px',
                                }}
                            />
                            <div className="nicdark_activity nicdark_marginleft100" style={{}}>
                                <h4 style={{ marginLeft: '0px', padding: '0px', fontWeight: 'normal' }}>
                                Solar Panel
                                </h4>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left' }}>&nbsp;</div>
                                <div>
                                <p style={{ margin: '0px', padding: '0px', fontSize: '15px', lineHeight: '22px', color: 'rgb(164, 164, 164)' }}>
                                Karavanlarımızda yaşam akülerinin şarjı için araç üstü solar paneller bulunmaktadır.
                                </p>
                                </div>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left'  }}>&nbsp;</div>
                            </div>
                            </div>
                            <div className="nicdark_textevidence nicdark_border_grey nicdark_relative" style={{border:'1px solid rgb(241,241,241)',position:'relative',float:'left',overflow:'hidden',padding:'10px'}}>
                            
                            <img
                                alt=""
                                className="nicdark_width_fix_50 nicdark_absolute_left nicdark_absolute"
                                src="https://www.karavanci.com.tr/ups/img12.png"
                                style={{
                                
                                left: '0px',
                                marginTop: '20px',
                                marginLeft: '20px',
                                width: '50px',
                                }}
                            />
                            <div className="nicdark_activity nicdark_marginleft100" style={{}}>
                                <h4 style={{ marginLeft: '0px', padding: '0px', fontWeight: 'normal' }}>
                                Şarjlı İnvertör
                                </h4>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left' }}>&nbsp;</div>
                                <div>
                                <p style={{ margin: '0px', padding: '0px', fontSize: '15px', lineHeight: '22px', color: 'rgb(164, 164, 164)' }}>
                                Karavan hareket halindeyken ve 220V enerji kaynağına bağlıyken yaşam akülerini şarj etmektedir.
                                </p>
                                </div>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left'  }}>&nbsp;</div>
                            </div>
                            </div>
                            <div className="nicdark_textevidence nicdark_border_grey nicdark_relative" style={{border:'1px solid rgb(241,241,241)',position:'relative',float:'left',overflow:'hidden',padding:'10px'}}>
                            
                            <img
                                alt=""
                                className="nicdark_width_fix_50 nicdark_absolute_left nicdark_absolute"
                                src="https://www.karavanci.com.tr/ups/img17.png"
                                style={{
                               
                                left: '0px',
                                marginTop: '20px',
                                marginLeft: '20px',
                                width: '50px',
                                }}
                            />
                            <div className="nicdark_activity nicdark_marginleft100" style={{}}>
                                <h4 style={{ marginLeft: '0px', padding: '0px', fontWeight: 'normal' }}>
                                Uydu Anten

                                </h4>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left' }}>&nbsp;</div>
                                <div>
                                    <p style={{ margin: '0px', padding: '0px', fontSize: '15px', lineHeight: '22px', color: 'rgb(164, 164, 164)' }}>
                                    Karavanlarımızda TV kullanımı için NETA anten kullanılmaktadır.
                                    </p>
                                </div>
                                <div className="nicdark_space20" style={{ height: '20px', width: '280px',float:'left'  }}>&nbsp;</div>
                            </div>
                            </div>
                            {/* ... diğer öğeler */}
                        </div>
                    
                    </div>
                    </div>
                    

                </div>
            
    </>
</HelmetProvider>
  )
}

export default Hizmetler