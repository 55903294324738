import React,{useRef,useEffect} from 'react'
import './Services.css'
import gsap from 'gsap'
import ReactDOM from "react-dom";


import BannerArkaplan from '../Assets/banner-arkaplan.svg'
import caravanIcon from '../Assets/caravanIcon-2.svg'
import patiIcon from '../Assets/patiIcon-2.svg'
import destekIcon from '../Assets/destekIcon-2.svg'
import planlamaIcon from '../Assets/planlamaIcon-2.svg'



const Services = () => {
  // const squareRef = useRef(null);
  //         useEffect(() => {
  //           gsap.to("#left", {
  //             y: 20,
  //             duration: 1,
  //             scrollTrigger: {
  //               trigger: "#left",
  //               start: "top center",
  //               end: "bottom 200px",
                
  //               scrub: 3,
  //             }
  //           });
  //         }, []);
          useEffect(()=>{
            let mm = gsap.matchMedia();
          mm.add("(min-width:768px)",()=>{
            gsap.to("#right", {
              y: 30,
              duration: 1,
              scrollTrigger: {
                trigger: "#right",
                start: "top center",
                end: "bottom 200px",
                scrub: 1,
              }
            });
          })
          return () => mm.revert();
          })
          useEffect(()=>{
            let mm = gsap.matchMedia();
          mm.add("(min-width:768px)",()=>{
            gsap.to("#left", {
              y: -40,
              duration: 1,
              scrollTrigger: {
                trigger: "#left",
                start: "top center",
                end: "bottom 200px",
                scrub: 2,
              }
            });
          })
          return () => mm.revert();
          })
         
  
  return (
    <div className='services'>
        <div className="container">
          <div className="icerik">
            <div className="hizmetler" id='hizmetler'>
              <div className="yazilar">
                <h2 className='baslik2'><span style={{fontWeight:'400'}}>Keşfetmeye hazır mısınız?</span>
                   Karavan Kiralama İle Özgürlüğün Tadını Çıkarın !
                </h2>
                <p className='icerikText'> <strong>Vizyonsuz Karavan'ın</strong> profesyonel karavan kiralama hizmetiyle özgürlüğünüze konfor katıyor! ; Keşfetmeye hazır mısınız? Yepyeni rotalarda gezerken doğanın güzelliklerini keşfetmek, kültürler arası köprüler kurmak ve anılarınızı ölümsüzleştirmek için vizyonumuzla size eşlik ediyoruz.</p>
              </div>
              <div className="banners">
             
                <div className="banner" id='left'>
                  <img id='bannerblue' src={BannerArkaplan} alt="" width={350} />
                  <div className="hizmetCard">
                    <img src={caravanIcon} alt="" width='150px' height='150px'/>
                  <p>Çekme Karavan Kiralama</p>
                  </div>
                </div>
                <div className="banner" id='right'>
                  <img id='bannerblue' src={BannerArkaplan} alt="" width={320} />
                  <div className="hizmetCard">
                  <img src={patiIcon} alt="" width='150px' height='150px'/>
                  <p>Pati Dostu Karavanlar</p>
                  </div>
                </div>
                <div className="banner" id='left'>
                  <img id='bannerblue' src={BannerArkaplan} alt="" width={300} />
                  <div className="hizmetCard">
                  <img src={destekIcon} alt="" width='150px' height='150px'/>
                  <p>7/24 Canlı Destek</p>
                  </div>
                </div>
                <div className="banner" id='right'>
                  <img  id='bannerblue' src={BannerArkaplan} alt="" width={290} />
                  <div className="hizmetCard">
                  <img src={planlamaIcon} alt="" width='150px' height='150px'/>
                  <p>Planlama, Yönlendirme ve Destek</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        
    </div>
  )
}

export default Services