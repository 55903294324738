import image1 from '../Assets/1.jpg'
import image3 from '../Assets/3.jpg'
import image4 from '../Assets/4.jpg'
import image2 from '../Assets/5.jpg'
import image5 from '../Assets/10.jpg'
import image6 from '../Assets/11.jpg'
import image7 from '../Assets/12.jpg'

export const images = [image1, image2, image3, image4,image5,image6,image7]

const imageByIndex = (index) => images[index % images.length]

export default imageByIndex
