import React from 'react'
import './HowTo.css'


import Bir from '../Assets/9.jpg'
import Iki from '../Assets/56.jpg'
import Uc from '../Assets/8024466.jpg'
import Dort from '../Assets/rezCardd.png'


const HowTo = () => {
  return (
    <div className='HowTo'>
        <div className='container'>
            <div className='how'>
                <div className="" style={{boxSizing: 'border-box'}}>
                    <h2 className='baslik2'><span style={{fontWeight:'400'}}>Vizyonsuz Karavan Kiralama ile</span>
                        Müşterilerimize Profesyonel Hizmetler Sunuyoruz
                    </h2>
                    <div className="HowToCards">
                        <div className='howBox'>
                            <div className='howToCard'><img src={Bir} alt=""  loading='lazy'/></div>
                            <h3 className='cardBaslik'>Karavanını Seç</h3>
                            <p className='cardText' style={{textAlign:'center'}}>Vizyonsuz Karavan Kiralama ile çekme karavanınızı seçin, özgürlüğün tadını çıkarın! Farklı modeller, konfor seviyeleri ve uygun fiyatlarla hemen rezervasyon yapın.</p>
                        </div>
                        <div className='howBox'>
                            <div className='howToCard'><img src={Dort} alt="" loading='lazy' /></div>
                            <h3  className='cardBaslik'>Rezervasyon Yap</h3>
                            <p  className='cardText'  style={{textAlign:'center'}}>Keyifli bir karavan deneyimi için hemen rezervasyon yapın! Geniş araç seçenekleri ve uygun fiyatlarla hayalinizdeki seyahate bir adım daha yaklaşın</p>
                        </div>
                        <div className='howBox'>
                            <div className='howToCard'><img src={Iki} alt=""  loading='lazy'/></div>
                            <h3 className='cardBaslik'>Yola Çık</h3>
                            <p className='cardText'  style={{textAlign:'center'}}>Hemen karavanınızı kiralayın ve özgürce yola çıkmanın keyfini yaşayın! En uygun fiyatlar ve geniş araç seçenekleriyle unutulmaz bir seyahate hazır olun.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HowTo