import React,{useEffect,useRef} from 'react'
import './BizKimiz.css'
import gsap from 'gsap'


import caravanBanner from '../Assets/karavanbanner2.jpg'
import caravanBanner2 from '../Assets/karavanbanner4.jpg'
import caravanBanner6 from '../Assets/karavanbanner6.jpg'
import karavansquare from '../Assets/karavansquare.svg'

const BizKimiz = () => {
    
        const circleRef = useRef(null);
          useEffect(() => {
            gsap.to("#mobile", {
              y: 20,
              duration: 1,
              scrollTrigger: {
                trigger: "#mobile",
                start: "top center",
                end: "bottom 200px",
                
                scrub: 3,
              }
            });
          }, []);
        
  return (
    <div className='BizKimiz'>
        <div className="container">
            <div className="bizContent">
                <div className="bizText gridIcerik">
                    <div className='bizImg'>
                        <div className='biz-img'>
                            <img className='bizbanner' src={caravanBanner} alt="" width='650px' height='auto' loading='lazy'/>
                        </div>
                        <img className='bannersquare' src={karavansquare} alt="" width='300px' loading='lazy'/>
                        <div id='mobile' className='banner-square'>
                            <img className='bannerabs bizbanner'  src={caravanBanner6} alt="" width='270px' height='auto' loading='lazy'/>
                        </div>
                    </div>
                    <div className='iceriks'>
                        <h2 className='baslik2'><span style={{fontWeight:'400'}}>Biz Kimiz ?</span>
                            Karavan Kiralama İhtiyaçlarınıza En İyi Çözüm !
                        </h2>
                        <p className='icerikText'>Sabit yerde duran kiralık çekme karavanlar, konfor ve özgürlüğü bir araya getirir. Hem durağan bir yaşam alanına sahip olursunuz hem de dilediğiniz zaman yeni yerler keşfetme özgürlüğüne sahip olursunuz. </p>
                        <div className="bizLists gridIcerik">
                        <ul className='bizList'>
                            <li><i class="fa-solid fa-check iconn"></i> Kaliteli Hizmet Garantisi</li>
                            <li><i class="fa-solid fa-check iconn"></i> Paha Biçilmez Deneyim</li>
                            <li><i class="fa-solid fa-check iconn"></i> Seçenekli Kamp Aracı</li>
                        </ul>
                        <ul className='bizList'>
                            <li><i class="fa-solid fa-check iconn"></i> En İyi Kalite Kamp Aracı</li>
                            <li><i class="fa-solid fa-check iconn"></i> Konforlu Yolculuk</li>
                            <li><i class="fa-solid fa-check iconn"></i> Unutulmaz Deneyimler</li>
                        </ul>
                        </div>
                        <p className='icerikText'>Deniz kıyısında romantik bir kaçamak, dağların arasında bir doğa tatili veya festivalleri takip ederken konforlu bir konaklama; çekme karavanla seyahatin olanakları sınırsızdır.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BizKimiz