import React, { useLayoutEffect, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
gsap.registerPlugin(ScrollTrigger);

// SlideUp animation
export const SplitWord = ({ children, stagger }) => {
  const elementRef = useRef(null);
  
  useLayoutEffect(() => {
    const element = elementRef.current;
    const ctx = gsap.context(() => {
      const textsplit = new SplitType(element, { types: "words" });
      const words = textsplit.words;
      let animation;
      const animateIn = () => {     
        if (animation && animation.isActive()) {
          return;
        }
        animation = gsap.fromTo(
          words,
          { x: -10, rotationX: 45, opacity: 0 },
          {
            x: 0,
            rotationX: 0,
            opacity: 1,
            overwrite: true,
            stagger: stagger || 0.02,
            duration: 2,
            ease: "power3.out",
          }
        );
      };

      gsap.set(words, { opacity: 0 });

      ScrollTrigger.create({
        trigger: element,
        start: "top bottom",
        onEnter: animateIn,
      });
    });

    return () => ctx.revert();
  }, [stagger, children]);

  return <div ref={elementRef}>{children}</div>;
};

// Expand divider animation
export const ExpandDivider = ({ children }) => {
  const dividerRef = useRef(null);

  useEffect(() => {
    const divider = dividerRef.current;
    let retrigger = true;
    const animateIn = () => {
      gsap.fromTo(
        divider,
        { width: '0%', transformOrigin: 'left' },
        { width: '100%', duration: 2, ease: 'power3.out' }
      );

      gsap.fromTo(
        divider,
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: 'power3.out' }
      );
    };

    const scrollTrigger = ScrollTrigger.create({
      trigger: divider,
      start: 'top bottom',
      onEnter: () => {
        if (retrigger) {
          animateIn();
          retrigger = false;
          setTimeout(() => {
            retrigger = true;
          }, 0);
        }
      },
      scrub: true,
    });

    gsap.set(divider, { width: '0%', opacity: 0 });

    return () => {
      scrollTrigger.kill();
    };
  }, []);

  return <div ref={dividerRef}>{children}</div>;
};
