import React from 'react'
import BreadCrump from '../BreadCrump/BreadCrump'
import Gallery from './Gallery'

const Images = () => {
  return (
    <div>
      <BreadCrump title='Galeri' backtotop='galeri'/>
      <Gallery/>
      
    </div>
  )
}

export default Images