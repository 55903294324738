import React, { useState, useRef, useEffect } from 'react';
import './VideoGallery.css';

import vid1 from '../Assets/VizyonsuzVd/141.mp4';
import vid2 from '../Assets/VizyonsuzVd/143.mp4';
import vid3 from '../Assets/VizyonsuzVd/144.mp4';
import vid4 from '../Assets/VizyonsuzVd/145.mp4';
import vid5 from '../Assets/VizyonsuzVd/146.mp4';
import vid6 from '../Assets/VizyonsuzVd/147.mp4';
import vid7 from '../Assets/VizyonsuzVd/148.mp4';
import vid8 from '../Assets/VizyonsuzVd/142.mp4';
// Diğer videoların import edilmesi

const Videos = () => {
  let data = [
    { id: 1, videSrc: vid1 },
    { id: 2, videSrc: vid2 },
    { id: 3, videSrc: vid3 },
    { id: 4, videSrc: vid4 },
    { id: 5, videSrc: vid5 },
    { id: 6, videSrc: vid6 },
    { id: 7, videSrc: vid7 },
    { id: 8, videSrc: vid8 },
    // Diğer video objeleri
  ];

  const [model, setModel] = useState(false);
  const [tempVideoSrc, setTempVideoSrc] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);

  const openVideoModel = (videoSrc) => {
    setTempVideoSrc(videoSrc);
    setModel(true);
    setIsPlaying(true);
  };

  const closeVideoModel = () => {
    setModel(false);
    setTempVideoSrc('');
  };

  useEffect(() => {
    if (!model && isPlaying) {
      setIsPlaying(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [model, isPlaying]);

  return (
    <>
      <div className={model ? 'model open' : 'model'} onClick={closeVideoModel}>
        <video
          src={tempVideoSrc}
          controls
          autoPlay
          ref={videoRef}
          onMouseEnter={() => setIsPlaying(true)}
          onMouseLeave={() => setIsPlaying(false)}
        />
        <i class="fa-solid fa-xmark" onClick={()=>setModel(false)}></i>
      </div>

      <div className="vidGallery">
        {data.map((item, index) => {
          return (
            <div className="videos" key={index} onClick={() => openVideoModel(item.videSrc)}>
              <video src={item.videSrc} controls loading="lazy" />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Videos;